import Cookies from 'js-cookie'
import {revealHeadings} from "./animations";
/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

require('./bootstrap');

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const oldestContainer = document.getElementById('oldest');
const oldestButton = document.getElementById('oldest-yes-button')
if (Cookies.get('oldest')) {
    oldestContainer.style.display = 'none';

}

oldestButton.addEventListener('click', function (e) {
    e.preventDefault();
    Cookies.set('oldest', true);
    oldestContainer.style.display = 'none';
})


/* ************************************* animations ***************************** */
revealHeadings();
