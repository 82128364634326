import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


export const revealHeadings = () => {
    const headings = Array.from(document.querySelectorAll("h1:not(.hero-heading)"));
    headings.forEach((h) => {
        gsap.from(h, {
            autoAlpha: 0.1,
            y: 70,
            scrollTrigger: {
                trigger: h,
                start: "top 90%"
            },
        });
    });

};

